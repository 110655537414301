/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */


.mtop{
    margin-top : 250px
}

.ptop{
    margin-top: -43px!important
}

.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td{
    padding: 14.5px;
}

caption{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  
}
caption.lieu_title {
    font-size: 30px;
    font-weight: 700;
    color: #000;
}

.table td {
    text-align: center;   
 }

 .table th {
    text-align: center;   
 }