a.cv_link {
    text-align: center !important;
    display: block;
    font-size: 19px;
    border: 1px solid #009688;
    border-radius: 5px;
    color: #009688;
}
.show-btn {
    border-radius: 50%;
    width: 41px;
    height: 41px;
}

div#item_content table tbody tr, div#item_content table tbody tr td {
    vertical-align: middle;
}
.cv_container button {
    width: 100%;
    text-align: center;
    color: #fff
}
.cv_container button i {
    color: #ff8900;
    font-size: 32px;
}

.cv_container button:hover {
    background: transparent !important;
    color: #009688;
    border: 1px solid #009688;
}
.convene_text {
    font-size: 18px;
    font-weight: 600;
}
button#sending_item {
    border-radius: 50%;
    height: 41px;
    width: 41px;
    color: #fff;
}
.created_date_content{
    background: #ececec;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
}
button#archived_btn {
    border-radius: 50%;
    width: 41px;
    height: 41px;
}
div#bakeli-stage {
    padding-top: 31px;
}
.col-md-12.vs-table-col {
    margin-top: 27px;
}
#item_content li.active {
    font-weight: 600;
}
li.active a {
    color: #ff9800 !important;
    border-top: 2px solid #ff9800 !important;
}