@font-face {
  font-family: "Din";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/DIN\ Alternate\ Regular.otf");
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**************************************************/

img.logo_img {
  width: 75px;
}
img.user_avatar {
  width: 25px;
}
/* div#page_container, .navbar-inverse .navbar-nav>.open>a {
  background: #ececec !important;
} */
a.dropdown-toggle:hover,
span.caret,
span.userFullName {
  color: #000000;
}
.si_menu_dropdown li a {
  font-weight: 600;
}

.img-circle.avatar {
  border: 2px solid #ccc;
  padding: 2px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-top: 2px solid #337ab7;
}

/* --------------------------------------------------
:: Page Wrapper
-------------------------------------------------- */

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 220px;
    /* padding: 50px 30px 0 20px; */
    border-left: 1px solid #e7e7e7;
  }
}

@media (max-width: 767px) {
  .sidebar-open #page-wrapper {
    transform: inherit !important;
  }
  .navbar-default.sidebar {
    padding-top: 0;
  }
}
/* --------------------------------------------------
:: Sidebar
-------------------------------------------------- */

.navbar-brand {
  width: 221px;
  border-right: 1px solid #dfdfdf;
}

body.sidebar-collapse .sidebar {
  margin-left: -220px;
}

body.sidebar-collapse #page-wrapper {
  margin-left: 0px;
}

@media (min-width: 1200px) and (max-width: 1800px) {
  .panel-widget .panel-heading {
    height: 112px;
  }
}

.sidebar,
#page-wrapper {
  min-height: 100%;
  transition: all 0.4s ease 0s;
}

.sidebar .sidebar-nav.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.sidebar .sidebar-avatar {
  padding: 15px;
  text-align: center;
}

.sidebar .sidebar-avatar .name {
  margin-top: 10px;
  color: #555555;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar ul li {
  border-bottom: 1px solid #e7e7e7;
}

.sidebar li.active.open > a:first-child {
  background-color: #337ab7;
  border-color: #2e6da4;
  color: #fff;
}

.sidebar ul li a.active {
  background-color: #eee;
}

.sidebar .arrow {
  float: right;
}

.sidebar .fa.arrow:before {
  content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
  content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: 0 !important;
}

.sidebar .nav-second-level li a {
  padding-left: 37px;
}

.sidebar .nav-third-level li a {
  padding-left: 52px;
}

#user-nav a {
  padding-bottom: 5px;
  padding-top: 5px;
}

#user-nav .nav-avatar {
  float: none;
  width: 35px;
  margin-right: 10px;
}

#sidebar-toggle {
  float: left;
  padding: 18px 15px 13px 15px;
}

#sidebar-toggle i {
  font-size: 16px;
  color: #888;
}

.navbar-right {
  float: right !important;
  /*  margin-right: -15px;
  display: flex; */
}

.navbar-brand img {
  margin-left: 25px;
}

@media (max-width: 767px) {
  #page-wrapper {
    margin-top: 100px;
  }
  .navbar-nav {
    margin: 0px -15px !important;
  }
  .sidebar-open #page-wrapper {
    -webkit-transform: translate(220px, 0);
    -ms-transform: translate(220px, 0);
    -o-transform: translate(220px, 0);
    transform: translate(220px, 0);
  }
  */ body.sidebar-open {
    overflow-x: hidden;
    /* -webkit-transform: translate(220px, 0);
      -ms-transform: translate(220px, 0);
      -o-transform: translate(220px, 0);
      transform: translate(220px, 0); */
    margin-left: 280px;
  }

  .navbar-brand {
    width: 100%;
    border-right: none;
  }

  .navbar-brand img {
    margin: 0 auto;
  }

  .sidebar {
    -webkit-transform: translate(-220px, 0);
    -ms-transform: translate(-220px, 0);
    -o-transform: translate(-220px, 0);
    transform: translate(-220px, 0);
    margin-top: 141px !important;
  }

  .sidebar-open .sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.sidebar {
  z-index: 1;
  position: absolute;
  width: 220px;
  padding-top: 51px;
  bottom: 0;
  top: 0;
  left: 0;
  min-height: 100%;
}

/* --------------------------------------------------
:: Header Dropdown Menu
-------------------------------------------------- */

.dropdown-menu.extended li a {
  padding: 15px 10px !important;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #ebebeb !important;
  font-size: 12px;
  list-style: none;
}

.dropdown-menu.inbox li a .photo img {
  float: left;
  height: 50px;
  margin-right: 4px;
}

.dropdown-menu.inbox li a .subject {
  display: block;
}

.dropdown-menu.inbox li a .subject .from {
  font-size: 12px;
  font-weight: 600;
}

.dropdown-menu.inbox li a .subject .time {
  font-size: 11px;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  right: 5px;
}

.dropdown-menu.inbox li a .message {
  display: block !important;
  font-size: 11px;
}

.dropdown-menu.extended li p {
  background-color: #f1f2f7;
  color: #666666;
  margin: 0;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
}

.dropdown-menu.extended {
  width: 260px;
  padding-top: 0;
  padding-bottom: 0;
}

.header-nav .dropdown-toggle {
  padding-top: 12px;
  padding-bottom: 5px;
}

.header-nav i {
  font-size: 26px;
}

.header-nav .badge {
  position: absolute;
  top: 5px;
  right: 5px;
}

.header-nav .badge.bg-green {
  background-color: #1fa67b;
}

.header-nav .badge.bg-orange {
  background-color: #ff5b57;
}

.dropdown-toggle.avatar {
  padding-top: 5px;
  padding-bottom: 3px;
}

.dropdown-toggle.avatar img {
  width: 40px;
  margin-right: 4px;
}

/* --------------------------------------------------
:: Page Header & Breadcrumbs
-------------------------------------------------- */

.page-header {
  margin: 30px 0 20px;
}

.page-header small {
  font-size: 50%;
}

.breadcrumb {
  padding: 10px 0 0 0;
  list-style: none;
  font-size: 14px;
  background-color: inherit;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .page-header {
    word-break: break-all;
  }
  .breadcrumb {
    margin: 10px 0;
  }
}

span.page-title {
  font-size: 30px;
  font-weight: 700;
}

.sidebar ul li a.active {
  background-color: #ff9900 !important;
  color: #000 !important;
  font-weight: 600 !important;
}

/**********************/
.button {
  position: relative;
  display: block;
  /* margin: 30px auto; */
  padding: 12px;
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(170, 170, 170, 0.6);
  /* background-color: #2ecc71; */
  color: #ecf0f1;
  transition: background-color 0.3s;
}

/* .button:hover, .button:focus {
  background-color: #27ae60;
} */

.button > * {
  position: relative;
}

.button span {
  display: block;
  padding: 12px 24px;
}

.button:before {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 0;
  padding-top: 0;

  border-radius: 100%;

  background-color: #ff8900 !important;
  /* rgba(236, 240, 241, .3) */
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.button:active:before {
  width: 120%;
  padding-top: 120%;
  background-color: #ff8900 !important;
  color: #ff9800 !important;
  transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}

.empty_data {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.add-coach-btn-container {
  text-align: center;
}
#btn_assign {
  background-color: #f9f9f9;
  -webkit-text-fill-color: #009688;
  border-color: #d1d1d1;
  font-family: "Din";
}
.add-coach-btn-container a,
.add-coach-btn-container button,
.add-reporting-btn-container button {
  border-radius: 50%;
}
.add_coach_btn,
.add_bakeliste_btn,
.liste_prospect,
.add_tache_btn,
.add_reporting_btn {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  margin: 25px 0;
}
.add_coach_btn i,
.add_bakeliste_btn i,
.liste_prospect i,
.add_tache_btn i,
.add_reporting_btn i,
.avliste_btn i,
.add_reporting_btn {
  display: inline-block;

  left: 0;
  right: 0;
  margin: auto;
  font-size: 75px;
}
.avliste_btn.button {
  display: inline-block !important;
}
.addBtn.button {
  /* width: 100%; */
  font-size: 18px;
  font-weight: 600;
  background: #009688;
}

.submit {
  width: 100%;
}
td.actions-btn-container button,
div.actions-btn-container button,
div.actions-btn-container a {
  margin: 5px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}
td.actions-btn-container,
div.actions-btn-container {
  text-align: center;
}
#btn_show_cv i {
  line-height: 2;
}

a#btn_show_cv {
  background: transparent;
  color: #ff9800;
  border-color: #ff9800;
}
.errorMsg {
  background: #f03b2e;
  color: #fff !important;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}
.errorMsg:before {
  content: "";
  position: absolute;
  right: 1px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f03b2e;
}
.cancel_btn {
  background: #d9534f;
  width: 205px;
  height: 51px;
  float: left;
  font-size: 18px;
  font-weight: 600;
}
.btn_for_assignation,
.btn_for_conversion {
  background: #009688;
  float: right;
}
.btn_for_wait {
  background: #ff9800 !important;
  float: right;
}
.todayClass {
  background: #19c15e !important;
  color: #fff;
  font-weight: 600;
}
tbody.rdv_table tr,
tbody.rdv_table tr th,
tbody.rdv_table tr td {
  vertical-align: middle;
}

.bsi-submit-btn {
  background: #009688 !important;
  padding: 15px;
  width: 120px;
  border-color: #009688 !important;
  font-weight: 600;
}

.bsi-cancel-btn {
  padding: 15px;
  width: 120px;
  font-weight: 600;
  float: initial !important;
}

.bsi-inprogress-btn {
  padding: 15px;
  width: 234px;
  font-weight: 600;
}

.bsi-submit-btn:active,
.bsi-submit-btn:hover {
  background: #009688 !important;
  border-color: #009688 !important;
}
.bsi-action-show-btn,
.bsi-action-show-btn:hover,
.bsi-action-show-btn:active,
.bsi-action-show-btn:focus {
  background: #009688 !important;
  border: 1px solid transparent !important;
}
.label.bsi-status {
  cursor: pointer;
}
.submitLogoutBtn {
  width: 100% !important;
}

.volkeno-green-color {
  color: #009688 !important;
}
.volkeno-green-bcolor {
  background: #009688 !important;
  border-color: #009688 !important;
}
.volkeno-orange-color {
  color: #ff8900 !important;
}
.volkeno-orange-bcolor {
  background: #ff8900 !important;
}
button.edit-btn {
  border-radius: 50%;
  width: 41px;
  height: 41px;
  color: #fff;
  border: 0px solid transparent;
}
button.completed-btn,
button.completed-btn:hover,
button.completed-btn:focus,
button.completed-btn:active {
  background: #009688;
  border-color: #009688 !important;
  border-radius: 50%;
  width: 41px;
  height: 41px;
  color: #fff;
}
button.show-item-btn,
button.show-item-btn:hover,
button.show-item-btn:focus,
button.show-item-btn:active {
  background: transparent;
  border-color: #009688 !important;
  color: #009688;
  border-radius: 50%;
  width: 41px;
  height: 41px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff !important;
  cursor: default;
  background-color: #009688 !important;
  border-color: #009688 !important;
}
a.page-link {
  color: #000 !important;
}
span.label_content.cbakelistes {
  font-size: 28px;
  font-weight: 600;
  display: inline-block;
  background: #009688;
  width: 152px;
  height: 43px;
  text-align: center;
  border-radius: 60px;
  color: #fff;
}
.pendingLoginBtn {
  background: #009688 !important;
}
.relase-container {
  border: 1px solid #009688;
  text-align: center;
  background: #009688;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  border-radius: 10px;
}
.notes-admin {
  background: #ececec;
  padding: 10px;
  border-radius: 6px;
}
.rdv-table table tr {
  vertical-align: middle;
}
.rdv-table table tr td {
  vertical-align: middle;
}
.add-coach-btn-container {
  text-align: center;
}
.add_coach_btn,
.add_bakeliste_btn,
.liste_prospect {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  margin: 25px 0;
}
.add_coach_btn i,
.add_bakeliste_btn i,
.liste_prospect i {
  display: inline-block;
  /* position: absolute; */
  left: 0;
  right: 0;
  margin: auto;
  font-size: 75px;
}
.add_bakeliste_container a:first-child {
  background: #009688;
  border-radius: 50%;
}
.add_bakeliste_container a:nth-child(2) {
  background: #ff9800;
  border-radius: 50%;
}
button.btn.btn-primary.toggle-btn {
  background: #ff9800;
  border-color: #ff9800;
  font-size: 20px;
  font-weight: 600;
}

.comment-item {
  background: #e8e8e8;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 10px;
}

.comment-item b {
  display: block;
  border-bottom: 2px solid #fff;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

.comment-item p {
  text-align: justify;
  font-size: 15px;
}
.alert.alert-warning.empty-comment-alert {
  text-align: center;
  font-size: 17px;
}
.content_container {
  background: #e8e8e8;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  border-radius: 7px;
}
tbody td,
tbody tr td {
  vertical-align: middle !important;
}
.return-btn {
  font-size: 16px;
  font-weight: 600;
}
.empty-details-rencontre p,
.empty-rencontre p {
  font-size: 18px;
  font-weight: 600;
}

.empty-details-rencontre,
.empty-rencontre {
  margin-top: 77px;
  border: 1px solid #009688;
  border-radius: 10px;
}
.panel-volkeno-green .panel-footer {
  color: #009688 !important;
}
.panel-volkeno-orange .panel-footer {
  color: #ff9800 !important;
}
.panel-volkeno-red .panel-footer {
  color: #f44336 !important;
}
#side-menu a {
  color: #009688;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
}
.upsuccess-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 18px;
  background: #3b3f3d !important;
  color: #fff;
  z-index: 9999;
}
.toast-error-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 18px;
  background: #a94442 !important;
  color: #fff;
  z-index: 9999;
}

.volkeno-orange-color-wb {
  background: #fff !important;
  color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.react-bs-table table td,
.react-bs-table table th {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.volkeno-red-bcolor {
  background: #ec4134 !important;
}
.volkeno-red-color {
  color: #ec4134 !important;
}
.meeting-col-container .panel-widget .panel-heading {
  height: 260px !important;
}
span.meeting_url_label {
  display: block;
  font-size: 16px;
}

span.meeting_register_url {
  font-size: 12px;
  color: #ececec !important;
}
span.meeting_register_url a {
  color: #ececec !important;
}
.sidebar-nav.navbar-collapse {
  height: 1500px;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
  background: #ff9800;
  border-color: #ff9800;
}
.row.count-intern-row {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 6px;
  border-radius: 7px;
  margin: 0px;
}
.col-md-4.count-intern-icon-container {
  text-align: center;
  padding: 18px;
  background: #009688;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
span.degree-title {
  font-size: 17px;
  font-weight: 600;
}
span.count-number {
  font-size: 23px;
}
.detailsRencontre_container {
  margin-top: 51px;
}
.col-md-8.count-intern-content {
  text-align: center;
}
.counting-link {
  color: #000;
}

ul.dropdown-menu.si_menu_dropdown li a {
  color: #000;
  font-weight: 500;
  padding: 5px;
  margin: 5px;
  width: 277px;
  font-size: 16px;
}

ul.dropdown-menu.si_menu_dropdown li a:hover {
  background: #ff9800;
  color: #fff;
}

ul.dropdown-menu.si_menu_dropdown li a i {
  color: #00acd6;
}

button.btn.btn-danger.submitLogoutBtn.button {
  font-size: 17px;
  font-weight: 600;
}

button.btn.btn-danger.submitLogoutBtn.button:hover {
  background: #ff9800;
}

/**************** new **********************/
.bg-vgreen {
  background-color: #009688 !important;
}
.label-green-bgcolor {
  background: #009688 !important;
  color: #fff !important;
}
.volkeno-orange-bgcolor {
  background: #ff9800 !important;
}
.cancel_btn.cancel {
  background: #ec4134;
  color: #fff;
}

/*************** end **************************/

/***** Styles à supprimer aprés intégration totale de adminlte ***************/
.no-content-wrapper {
  background: #fff !important;
}
section.content {
  background: #fff;
}
button.btn.btn-success.submit-button {
  width: 100%;
  padding: 9px;
  font-size: 19px;
  background: #009688;
  border-color: #009688;
}
button.btn.btn-warning.submit-button {
  width: 100%;
  padding: 9px;
  font-size: 15px;
  background: #ff9800;
  border-color: #ff9800;
}
a#btn_show_tc {
  position: relative;
}

span.tc-counter {
  position: absolute;
  top: -17px;
  font-size: 15px;
  background: #ff9800;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* left: 0; */
  right: 0;
  margin: auto;
  font-weight: 600;
  line-height: 30px;
}
li.important-news {
  font-weight: 600;
  color: #009688;
  font-size: 15px;
}
.label-vgreen {
  background: #009688 !important;
}
.label-violet {
  background: #8a2be2;
}
sup.required-field {
  color: red;
}
.bg-vred {
  background: #dd4b39 !important;
}
.bg-vorange {
  background: #ff9800 !important;
}
.bg-vgreen {
  background: #009688 !important;
}
.modal-linky {
  cursor: pointer !important;
}
button.close {
  position: absolute;
  right: 23px;
  background: red;
  z-index: 99999;
  width: 24px;
  height: 26px;
  color: #fff;
  opacity: 1;
  border-radius: 5px;
}

.fullname-link,
.more-infos-linky,
.tache-link {
  cursor: pointer;
  color: #3c8dbc;
}
.si-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.si-tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999;
  padding-left: 5px;
  padding-right: 5px;
}

.si-tooltip:hover .tooltiptext {
  visibility: visible;
}
button#btn_recruitable {
  background: #fff;
  color: #009688;
  border: 1px solid #009688;
}
span.info-box-text {
  overflow: inherit !important;
}
.section-footer {
  margin-top: 24px !important;
  text-align: center !important;
  font-weight: 600;
  border: none !important;
  border-top: 1px solid #b3aeae !important;
  border-radius: 0px !important;
  margin-left: -26px;
  margin-right: -26px;
  margin-bottom: -25px;
  padding: 15px !important;
}
.activities_item_container.section-footer a,
.skills_item_container.section-footer a {
  color: #009688;
  padding: 15px;
}
.loading-btn {
  width: 100%;
  padding: 10px !important;
  font-size: 25px !important;
  background: transparent !important;
  border-color: #009688 !important;
  color: #009688 !important;
}
/* div#root {
  margin-top: 30px;
} */

a.forget-pwd-link {
  font-size: 16px;
  color: #009688 !important;
}

#loginForm input {
  height: 55px;
}
h1.text-base.text-primary.text-uppercase.mb-4.p_greeting {
  color: #009688 !important;
  font-weight: 900;
  font-size: 40px;
}

h2.mb-4.wlc_back {
  font-weight: 600;
  font-size: 20px;
}
.app_owner_logo_container {
  /* position: relative; */
  /* top: -67px; */
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
button.add_in_progress {
  border-color: #009688 !important;
  padding: 4px;
  font-size: 31px;
  background: transparent !important;
  color: #009688 !important;
  border-width: 2px;
}
img.img-responsive.app_owner_logo {
  position: relative;
  display: initial;
}
p.text-muted.app_descrption {
  font-size: 17px;
}
.ytb_tu {
  width: 100%;
}
.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.dyn_btn_container {
  display: none !important;
}

.col-md-6.item_rating_bloc {
  width: 50%;
  float: left;
}

.item-title {
  background: #ff9800;
  padding: 3px;
  height: 62px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: inherit;
  font-size: 15px;
  font-weight: 600;
}

.item-coach-notation,
.item-bakeli-notation {
  border: 1px solid #ff9800;
  padding: 4px;
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  color: #009688;
}

.comment-title {
  background: #ff9800;
  text-align: center;
  font-size: 25px;
  margin-top: 10px;
  padding: 11px;
  font-weight: 600;
}

/* .commentaire_container {
  border: 1px solid #ff9800;
} */

.comment_content {
  padding: 7px;
}
.row.comments-row {
  margin-top: 10px;
}

.commentaire_container {
  border: 1px solid #ff9800;
}

.comment_content {
  font-size: 16px;
}
.empty_comment_container {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #000 !important;
  padding: 15px;
}

/*** Syllabus Manager **********/
.add-btn-container {
  text-align: center;
  position: relative;
}
.bg-vgreen.add_btn.button {
  border-color: #009688;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
}
.row.actions_btn_row {
  margin: 20px 0;
}

button.disabled_btn {
  border-color: #dd4b39;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
}
.syllabus_item_container .box-body {
  background: #fff;
}
.syllabus_item_container .box-footer {
  background: #f1b864;
  border-top: 1px solid #009688 !important;
}
.row.add_syllabus_container {
  margin-bottom: 100px;
}
.syllabus_description_bloc {
  font-weight: 600;
}
.cancel-btn,
.submit_btn_container {
  width: 180px;
}
span.cancel_btn_label,
.submit_btn_label {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}
.submit_btn_container {
  border-color: #009688 !important;
}
.box_container {
  border: 1px solid #009688 !important;
}
button.export_pdf_btn.btn.btn-success.bg-vgreen {
  position: absolute;
  left: 190px;
  border-color: #009688;
}
.scm_table_container {
  position: relative;
}
li.beta-link a {
  color: #ec4134 !important;
}
a.syllabus_more_infos_link {
  display: inline-block;
  width: 150px;
  padding: 5px;
  margin: 7px;
  text-align: center;
  font-size: 15px;
  border: 1px solid #009688 !important;
  border-radius: 5px;
}
.box_container h3.box-title {
  color: #fff;
  font-size: 12px;
}
.description_container p {
  font-size: 13px;
}
.magic__Wrapper-dtlj8d-0.cyENQK {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

/****** End Style syllabus ************/

.col-md-12.radio_input_container {
  background: #ececec;
  padding: 18px;
}
.option_item {
  font-size: 17px;
  vertical-align: middle;
}
.radio_input_container input[type="radio"] {
  width: 24px;
  height: 30px;
  vertical-align: middle;
}
.option_item span {
  vertical-align: middle;
  margin-left: 5px;
}

.bg-vred {
  background: #f34336 !important;
}
.bg-black {
  background: #000000;
}

.radio_button_container input[type="radio"] {
  width: 26px;
  height: 26px;
  vertical-align: middle;
}
span.radio_button_label {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 45px;
}
.restant_container_inf_30 {
  color: #ff5b57 !important;
}
.restant_container_inf_30,
.restant_container_sup_30 {
  font-style: italic !important;
}
.syllabus_tt_container {
  border: 1px solid #009688 !important;
  padding: 15px;
  text-align: center;
  margin: 15px 0;
  font-weight: 600;
  height: 60px;
  vertical-align: middle;
  display: inline-block;
  width: 100% !important;
  text-transform: uppercase;
}
.description_container {
  height: 135px !important;
  color: #000000c9;
}
.empty_data_alerty {
  margin-top: 55px;
}
.item-ds-title {
  background: #ff9800;
  text-align: center;
  padding: 10px;
  font-size: 19px;
  font-weight: 600;
  margin-left: 0px !important;
}
.col-md-12.item_ds_bloc {
  margin-bottom: 23px;
  border: 1px solid #ff9800;
  padding-left: 0px;
  padding-right: 0px;
}
.item-ds-infos {
  padding: 15px;
}
.item-ds-title.partial {
  height: 74px;
  display: table-cell;
  vertical-align: middle;
}
.item-ds-infos.partial {
  border: 1px solid #ff9800;
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}
.edit_icon {
  background: #009688;
  border-style: none;
}
.show_icon {
  background: #ff9800 !important;
  border-style: none;
}
.bg-vblack {
  background: #000000 !important;
}
.add-item-btn {
  width: 100%;
  padding: 10px !important;
  font-size: 25px !important;
}
.submit-btn-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.start-hidden {
  display: none;
}
.connexion-skeleton-row {
  width: 100%;
}
