.syllabus_tt_container{
    border: 1px solid #009688 !important;
    /* padding: 15px; */
    /* text-align: center; */
    margin: 7px ;
    font-size: 12px;
    /* line-height: 5; */
    /* line-break: normal; */
    font-weight: 600;
    height: 5em;
    /* vertical-align: middle; */
    display: flex;
    width: 95% !important;
    text-transform: uppercase;
}
.description_container {
    height: 135px !important;
    color: #000000c9;
    margin: 10px;
}