.ui.small.image.bp_avatar_img-user {
    width: 80px;
  }
  .content.content-historique {
    min-height: auto;
}

.ui.very.relaxed.list.list-historique {
  margin-left: 10rem !important;
}

@media only screen and(max-width:767px){
  .ui.very.relaxed.list.list-historique {
    margin-left: 1px !important;
  }
  div#item-date {
    padding-left: 27px;
    margin-top: -18px !important;
}
}

.mb-3.mx-auto.historique {
  background: orange;
  border-radius: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 13%;
  position: relative;
  border: 2px solid #fff;
  padding-left: 10px;
  left: -61px;
  top: 39px;
}