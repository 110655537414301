
.component-cvtheque{
    
}
h4.cv_section_title {
    border-bottom: 1px solid #ff9800;
    padding-bottom: 9px;
}
h5.project_name_label {
    font-size: 15px;
    font-weight: 600;
}
h6.project_technologies_label {
    font-size: 13px;
    color: gray;
}
.col-md-6.taches-btn {
    text-align: right;
}
.col-md-12.button-create-cv-actions-container div {
    background: #ececec;
    padding: 5px;
}
i.diplome_container, span.school_container, span.diplome_label {
    font-weight: 600;
}
img.img_responsive.portfolio_img {
    width: 100%;
}
.portfolio_item_container .panel-heading {
    color: #fff;
    text-align: center;
    font-weight: 600;
}
.portfolio_item_container .panel-footer {
    cursor: pointer;
    text-align: center;
    color: #009688;
}
.techno_lang_title{
    font-size: 17px;
    border-bottom: 1px solid #ececec;
    background: #ff9800;
    padding: 7px;
}
.techno_container,
.lang_container {
    border: 1px solid #ececec;
    padding: 5px;
    border-radius: 5px;
}
.cvtheque_empty_warning i {
    font-size: 104px;
    text-align: center;
    display: inline-block;
    margin-bottom: 22px;
}
.cvtheque_empty_warning {
    text-align: center;
}
.cvtheque_empty_warning p {
    font-size: 25px;
}
.sumbmit-btn {
    font-size: 16px;
    font-weight: 600;
}