@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/DIN\ Alternate\ Regular.otf');
}

#assignBody {
    font-family: 'Din'
}

#btn_assign {
    background-color: #F9F9F9;
    -webkit-text-fill-color: #009688;
    border-color: #D1D1D1;
    font-family: 'Din'
}
.add-coach-btn-container a {
    border-radius: 50%;
}

#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}

.alert.alert-warning.empty-img-alert {
    font-size: 17px;
    text-align: center;
}
.linky-container.row {
    margin-bottom: 15px;
}
a.btn.btn-default.subscribe_link {
    padding: 15px;
    background: #ff9800;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
}
.alert.alert-warning.subscribe_empty_link_alert {
    font-size: 17px;
    text-align: center;
    font-weight: 600;
}
h4#showEventModalLabel {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
a.btn.btn-success.cliquable-btn {
    background: #009688;
}