.tc-loader {
    top: 0;
    border-top: 16px solid #009688 !important;
}
.error-row{
    margin-left: 15px;
    margin-right: 15px;
}
.col-md-4.icon-container i {font-size: 110px;line-height: 2;text-align: center;display: inherit;color: #ef6565;}

.col-md-12.load-image-alerty {
    border: 1px solid #ef6565;
    margin-bottom: 31px;
    border-radius: 10px;
}

h3.img-alerty-title {
    color: #ef6565;
    font-weight: 600;
    text-align: center;
}

span.created_at {
    float:right;
    margin-top: -21px;
}
span.content_type {
    display: block;
    margin-left: 287px;
    margin-top: -19px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 240px;
}
span.content_type> p> img {
    height: 20px;
}
a.list-group-item.list-group-item-action.contenu{
    border: 0;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-radius: 0;
}
span.action-format {
    display: none;
}
a.list-group-item.list-group-item-action.contenu:hover> span.action-format {
    display: block;
    float: right;
    margin-top: -23px;
    background: #f5f5f5;
}
.btn-add-content {
    display: none;
}

@media (max-width: 767px) {
    span.content_type {
        display: none;
    }
    span.created_at {
        margin-top: 0;
        font-size: 11px;
    }
    section.content-header {  
         margin-top: -55px;padding-bottom: 8px; 
         }
    #title-contenu{
         text-transform: uppercase; 
        }
    .content-header > h1{ 
        display: none;  
    }
    .content-header > .breadcrumb { 
        right: 4px; 
    }
    .box.box-solid.box-warning {
        display: none; 
    }
    a.list-group-item.list-group-item-action.contenu:hover> span.action-format {
        float: left;
        margin-top: 133px;
        position: fixed;
        top: 0 !important;
        padding-left: 114px;
        margin-left: -21px;
        background: #ececec;
    }
    .btn-add-content {
        position: fixed;
        /* margin-top: -13px; */
        /* margin-left: 278px; */
        display: block;
        bottom: 5px;
        right: 2%;
    }
    .btn-add-content a{
        font-size: 20px;
    }
}