#salesChart{
    height: 180px;
}
.progress-bar{
    width: 80%;
}
.chart-legend-container {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
span.bakeliste-legend {
    display: inline-block;
    width: 12px;
    background: #ff9800;
    height: 12px;
}
span.prospect-legend {
    display: inline-block;
    width: 12px;
    background: #009688;
    height: 12px;
}