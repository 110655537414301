
#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.empty_content {
    text-align: center;
    font-size: 18px;
}