i.teal.send.large.circular.link.icon {
  color: #009688 !important;
  border-color: #009688 !important;
  border: 3px solid;
  font-size: 20px;
}
i.green.circle.outline.large.icon {
  color: #00a65a !important;
}

i.green.user.icon {
  color: #00a65a !important;
  font-size: 20px;
  border-color: #00a65a !important;
  border: 3px solid;
}

i.icon.send:before {
  margin-left: -5px !important;
}

tr > td > input[type="checkbox"] {
  opacity: 5 !important;
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px;
  border-radius: 15px !important;
  border: 2px solid #8cad2d !important;
  background-color: #fff !important;
}

input.react-bs-select-all {
  width: 20px;
  height: 20px;
  opacity: 5;
  margin-left: -8px;
}
td > input[type="checkbox"]:checked::before {
  background-color: #2196f3 !important;
}
.prospect-row-row ul li a {
  font-size: 16px !important;
  font-family: none !important;
}

.col-md-12.pm_coly.send-to-mail {
  background: #009688 !important;
  //margin-top: -15px;
}
.alert.alert {
  color: white;
}

textarea.textarea-message {
  font-size: 14px !important;
}
i.green.check.circle.large.circular.link.icon {
  border: 2px solid !important;
}
