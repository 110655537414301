.loader-container{
    text-align: center;
}
.coach-notation-moyenne-col .box-header.with-border{
    background: #ff9800;
    color: #fff;
}
.notation-table-col .box-header.with-border{
    background: #009688;
    color: #fff;
}
.coach-notation-moyenne-col .box-tools.pull-right button,
.notation-table-col .box-tools.pull-right button{
    color: #fff;
}
.coach-notation-moyenne-col h3.box-title,
.notation-table-col h3.box-title{
    font-size: 22px;
    font-weight: 600;
}
.bakeli-notation-container span.progress-description {
    text-align: center;
    font-size: 16px;
    font-style: italic;
}