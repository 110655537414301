.col-md-3.cdb-item .col-intermediaire {
    border: 1px solid #000;
    padding: 0px;
    border-radius: 10px;
    text-align: center;
    font-size: 19px;
}
.cdb-item-title {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 6px;
    background: red;
    border-radius: 10px 10px 0px 0px;
    height: 25px;
    color: #fff;
}
h4.push-left.lieu_title {
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
}
.volkeno-foire-container.row {
    margin-right: 0px;
    margin-left: 0px;
}
h3.cdb-title {
    margin-left: 17px;
    font-weight: 600;
}
.cdb-switcher-btn-container.col-md-6 {
    position: absolute;
    right: 33px;
    text-align: right;
    top: 16px;
}
.row.title-and-btn-container {
    position: relative;
}
.filter-submit {
    width: 100%;
}
.alert.alert-warning.filter-alerty {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.col-md-3.filter-form {
    background: #e8e8e8;
}
.danger-alerty{
    margin-top: 5px;
}
.cdb-switcher-btn-container button {
    padding: 15px;
    font-size: 17px;
    font-weight: 600;
}
.noFilter-alert {
    margin-top: 53px;
}
.component-bakelistes-per-day .box-header {
    color: #fff;
    font-size: 18px;
}
.component-bakelistes-per-day .box-header h3.box-title {
    font-weight: 600;
}
.component-bakelistes-per-day .box-header button.btn.btn-box-tool {
    color: #fff !important;
}