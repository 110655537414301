.component-bakeliste-profile{
    margin-bottom: 50px;
}
.bp_couverture_container {
    background: #ff9800;
    height: 178px;
}
.user_avatar-container {
    position: absolute;
    width: 155px;
    top: 85px;
    /* background: #009688; */
    left: 34px;
}
header#bp_header {
    position: relative;
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    border-radius: 5px;
}
img.bp_avatar_img {
    width: 155px;
    border-radius: 50%;
    height: 155px;
    border: 4px solid #ff9800;
}
.bp_first_infos_container {
    padding-top: 70px;
    padding-left: 37px;
    background: #009688;
    padding-bottom: 25px;
}
h1.bp_fullname {
    font-size: 26px;
    font-weight: 900;
}
h2.bp_profile_title {
    font-weight: 500;
    font-size: 19px;
}
section#section-bio,
section#section-tb,
section#section-activities,
section#section-exp,
section#section-educ,
section#section-skills,
section#section-module,
section#section-extra {
    margin-top: 45px;
    border: 1px solid #bbb5b5;
    padding: 26px;
    text-align: justify;
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    transition: box-shadow 83ms;
    border-radius: 5px;
}
h3.section-title {
    font-size: 20px;
    font-weight: 600;
}
.row.tb_row {
    /* padding-top: 15px; */
    /* padding-bottom: 15px; */
    margin-left: 4px;
    margin-right: 4px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    -webkit-transition: box-shadow 83ms;
    transition: box-shadow 83ms;
    border-radius: 5px;
}
.col-md-4.tb_col_item {
    padding: 20px;
    border: 1px solid #bbb5b5;
}
.tb_count {
    font-size: 29px;
    font-weight: 600;
    color: #009688;
}
.tb_count_label {
    font-size: 14px;
}
.activities_item_container,
.skills_item_container {
    border: 1px solid #b3aeae;
    margin-top: 7px;
    padding: 6px;
    text-align: justify;
    border-radius: 5px;
}
h3.project_name {
    font-weight: 600;
    font-size: 18px;
}
.techno_tools {
    color: #00000075;
}
li.task_list_item {
    font-style: italic;
}
.degree_label {
    font-weight: 600;
    font-size: 15px;
}
span.degree_name {
    font-weight: 600;
}
h4.panel-title {
    color: #fff;
    font-weight: 600;
}

h5.module_item_title,
h5.extra_title {
    font-weight: 600;
}
h4.panel-title a {
    color: #ff9800;
}
h4.panel-title a.collapsed {
    color: #fff;
}
