
article.col-md-6.col-lg-6.col-xs-12.col-sm-6.art1 {
    display: none !important;
}
.embed-responsive-16by9 {
    padding-bottom: 113.25% !important;
    height: 200%;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    left: unset !important;
}

@media (max-width: 767px){
    .embed-responsive-16by9 {
        padding-bottom: 187.25% !important;
        height: 200%;
    }

    .embed-responsive {
        position: unset;
    }
}