.btn-assignation{
    background-color: #009688;
    color: white;
    margin-left: 10px;
    border-color: #009688;
    width: 100%;
    margin-right: 10px;
    height: 34px;
    font-size: 16px;
    padding: 6px;
    cursor: grabbing;
}
.syllabus_tt_container{
    border: 1px solid #009688 !important;
    /* padding: 15px; */
    /* text-align: center; */
    margin: 15px 0;
    font-size: 12px;
    /* line-height: 5; */
    /* line-break: normal; */
    font-weight: 600;
    height: 5em;
    /* vertical-align: middle; */
    display: flex;
    width: 100% !important;
    text-transform: uppercase;
}
.modal-contentA{
    background-color: white;
    position: relative;
    width: auto;
}