.info_row_bakeliste .box-body p {
    font-size: 16px;
    text-align: justify;
}
img.event_img.img-responsive {
    width: 100%;
    margin-bottom: 31px;
}
p.event_description_content {
    text-align: justify;
    font-size: 18px !important;
    background: #ececec;
    padding: 13px;
    border-radius: 10px;
}
.event_info_bakeliste_col .box-header.with-border .box-title {
    text-align: center !important;
    font-size: 23px;
    color: #000;
    font-weight: 600;
    /* text-shadow: 0px -1px 0px gray; */
}
.event_info_bakeliste_col .box-header.with-border {
    text-align: center !important;
}
h3.text-center.subscribe_title {
    font-weight: 600;
    border-bottom: 1px solid;
}

.event_subscribe_container.col-md-6.col-md-offset-3 {
    text-align: center;
}

a.subscribe_btn.btn.btn-warning {
    padding: 18px;
    font-size: 19px;
}
/* .component-bakeliste-home-dashboard .info-box-text{
    overflow: inherit !important;
} */

/**********************************************/
.pointme-col {
    text-align: center;
    padding: 26px;
    background: #d2d6de;
    border-radius: 15px;
}
.submit-pointme-btn-container {
    margin-top: 25px;
    width: 100%;
}
button.btn.btn-success.bg-vgreen.pointme_submit_btn, button.btn.btn-success.bg-vorange.pointme_inprogress_btn {
    width: 50%;
}
.page {
    margin-top: 8%;
    margin-right: -15px;
    margin-left: -15px;
}
span#basic-addon1 i {
    color: #009688 !important;
}
button.btn.btn-success.bg-vgreen.pointme_submit_btn {
    border-color: #009688 !important;
}
.pointage-container {
    font-size: 20px;
}

span.pointage-hour {
    font-weight: 900;
}

.departure-btn-container {
    margin-top: 15px;
}
.illustration {
    text-align:center;
    padding:0 0 20px;
    font-size: 27px;
    text-align: center;
    padding: 0 0 47px;
    font-size: 27px;
}
span.point-label {
    background: #009688;
    color: #fff;
    padding: 10px;
    font-weight: 600;
}
.logo_container {
    text-align: center;
}
span.me-label {
    color: #009688;
    font-weight: 600;
    background: #0096884d;
    padding: 10px 10px 10px 0;
}
.phone-warning{
    text-align: left !important;
    background: #0096884d !important;
    border-radius: 0px !important;
    border-color: transparent !important;
}
h3.departure_title {
    font-size: 20px;
    font-weight: 600;
    color: #dc3545;
}
span.pointage-hour {
    border-bottom: 5px double #009688;
}
.floate{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:rgb(0,150,136);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-floate{
	margin-top:22px;
}
.report{
    background-color: #d2d6de;
    width: 50%;
    height: 50%;
    margin-right: 8%;
}




#sidebare-wrapper {
    background-color: rgb(241, 5, 5);
    z-index: 1000;
    position: fixed;
    right: 280px;
    width: 0;
    height: 100%;
    margin-right: -100px;
    /* overflow-y: auto; */
    
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .nav-side {
    position: absolute;
    
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

.nav-side li {
    text-indent: 20px;
    line-height: 40px;
  }
  
  .nav-side li a {
    display: block;
    text-decoration: none;
    color: rgb(14, 13, 13);
    
  }
  
  .nav-side li a:hover {
    text-decoration: none;
    color: rgb(245, 150, 9);
    background: rgba(255, 255, 255, 0.2);
  }
  
  .nav-side li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
  }
  .panel-larg{
      width: 150%;
      margin-left:-30%;
      height: 100%;
  }
  .point-mee{
      margin-top:-5%;
      height: 60%;
      width: 150%;
      margin-left:-30%;
      
  }
  .pointe {
      width: 70%;
      height: 80%;
      /* border-radius: 50%; */
      /* background-color: teal; */
  }
  .arrondi{
    width: 180%;
    margin: 20px auto; /*Centre les div dans la page*/
    margin-top: -2% !important;
}
.point-labele{
    margin-left:0%;
}

.arrondi p{
  width: 70%;
  height: 100%;
  box-sizing: border-box;
  text-align:center; /*Texte centré horizontalement*/
  line-height: 100px; /*Texte centré verticalement*/
  background-color: #0CC; /*Fond bleu vert*/
}

.bordure{
    border: 2px solid black;
}
/* .arrondi1{
    border-radius : 100px / 20px;
} */
.arrondi2{
    border-radius: 50px;/*Identique à border-radius: 50px/50px*/
}
/* .arrondi3{
  border-radius: 25%;/*Identique à border-radius: 25%/25%*/
/* }  */
.control{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
border: medium rgb(8, 223, 8);
color: #4c4c4c;
font-size: 15px;
min-height: 48px;
width: 100%;
outline:none;
}