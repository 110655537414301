.navbar {
  background-color: #fffffff1;
  color: white !important;
  border-bottom: 1px solid #dedede !important;
}

.nav .only_admin_li li a,
.nav .only_coach_li li a,
.nav .only_bakeliste_li li a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.cInfos-title {
  border: 1px solid #fff;
  padding: 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
  border-radius: 5px;
}

.coach-infos {
  text-align: center;
  background: #33aca0;
  color: #fff;
}

span.cInfos-item-label {
  font-weight: 600;
}

.cInfos-title hr {
  border-top-color: #fff !important;
}
span.cInfos-item {
  overflow-wrap: break-word;
}
li.new-menu-item:after {
  width: 0;
  height: 0;
  border-top: 20px solid red;
  border-left: 20px solid transparent;
  position: absolute;
  content: "";
  top: 122px;
  right: 0;
}
.only_bakeliste_li {
  position: relative;
}

.flexbox {
  background: #009688;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  margin: 20px;
}

.search > h3 {
  font-weight: normal;
}

.search > h1,
.search > h3 {
  color: white;
  margin-bottom: 15px;
  text-shadow: 0 1px #0091c2;
}

.search > div {
  display: inline-block;
  position: relative;
}

/* .search > div:after {
  content: "";
  background: #fff;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 17px;
  right: -3px;
  transform: rotate(135deg);
  box-shadow: 1px 0 #fff;
} */

.search > div > input {
  color: #fff;
  font-size: 16px;
  background: transparent;
  /* width: 25px; */
  height: 25px;
  padding: 10px;
  border: solid 1px white;
  outline: none;
  border-radius: 35px;
  box-shadow: 0 1px #fff;
  transition: width 0.5s;
}

.search > div > input::placeholder {
  color: #efefef;
  opacity: 1;
}

.search > div > input::-ms-placeholder {
  color: #efefef;
}

.search > div > input::-ms-input-placeholder {
  color: #efefef;
}

/* .search > div > input:focus,
.search > div > input:valid {
  width: 250px;
} */

.open-modal {
  font-weight: bold;
  background: var(--blue);
  color: var(--white);
  padding: 0.75rem 1.75rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}
.modaly {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: var(--black);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;
}
.modal-dialog {
  position: relative;
  max-width: 800px;
  max-height: 100vh;
  border-radius: 5px;
  background: var(--white);
  overflow: auto;
  cursor: default;
}
.modal-dialog > * {
  padding: 1rem;
}

.modal-header,
.modal-footer {
  background: var(--lightgray);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header .modal-close {
  font-size: 1.5rem;
}

.modaly p + p {
  margin-top: 1rem;
}
.modaly {
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;
}

.modaly.is-visible {
  visibility: visible;
  opacity: 1;
}

.ReactModal__Content {
  position: absolute;
  top: 73px !important;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  z-index: 99999;
}
.navbar-default.sidebar {
  z-index: 1;
}
span.closy-modal {
  position: absolute;
  right: 13px;
  top: 8px;
  display: block;
  background: red;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.searchable_container button {
  position: absolute;
  right: 1px;
  background: #fff;
  border-color: transparent;
  border-radius: 30px;
  color: #ff9800;
}
h4.text-center.modaly-title {
  background: #009688;
  color: #fff;
  position: absolute;
  width: 100%;
  top: 0;
  margin-top: 0;
  left: 0;
  right: 0;
  padding: 15px;
}

.modal-infos-title {
  background: #009688;
  text-align: center;
  padding: 15px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin-top: 0;
}

.search-find-content {
  margin-top: 68px;
}

h4.text-center.modaly-error-title {
  background: #f36262;
  color: #fff;
  position: absolute;
  width: 100%;
  top: 0;
  margin-top: 0;
  left: 0;
  right: 0;
  padding: 15px;
}

.search-error-container {
  margin-top: 45px;
}

.search-error-icon {
  text-align: center;
  font-size: 29px;
  color: #f36262;
}

.search-error-content {
  text-align: center;
  margin-top: 23px;
  font-size: 19px;
}

/* new menu right style */

#collapseMobile-list {
  padding-right: 37px;
}
span#label-notification {
  margin-top: -3px !important;
  margin-right: 0 !important;
  border-radius: 42px !important;
  margin-left: -13px !important;
}
span#label-history {
  margin-top: 2px !important;
  margin-right: 0 !important;
  border-radius: 50px !important;
  margin-left: -13px !important;
}
#icon-mobile {
  top: 7px;
  position: relative;
  font-size: 25px;
  color: #383131;
}
.fa-comment-alt {
  font-size: 21px !important;
}
img#icon-mobile {
  width: 30px;
  top: 9px;
}
#user_avatar-mobile {
  margin-left: -12px;
  top: 7px;
  position: relative;
}
#user-avatar-fullname {
  padding-left: 26px;
  margin-top: -10px;
  color: #000;
  font-weight: 700;
}
a.user-compte {
  font-size: small;
  color: #000;
  font-family: sans-serif;
  text-decoration: none;
}
#logout-avatar {
  font-weight: 700;
  font-size: small;
  font-family: sans-serif;
}
div#item-dropdown-mobile {
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: none;
  opacity: 1;
  position: absolute;
  left: auto;
  right: -4px;
  visibility: visible;
  overflow: visible;
  min-width: 280px;
  width: auto;
  padding: 15px 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}
div#item-dropdown-bell {
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: none;
  opacity: 1;
  position: absolute;
  left: auto;
  visibility: visible;
  overflow: visible;
  min-width: 255px;
  width: auto;
  padding: 15px 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  font-size: small;
  font-family: monospace;
}

div#item-comment {
  margin-left: -195px;
  min-width: 39rem;
  width: auto;
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: none;
  opacity: 1;
  position: absolute;
  left: auto;
  visibility: visible;
  overflow: visible;
  padding: 15px 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  font-size: small;
  font-family: monospace;
}
div#item-history {
  margin-left: -195px;
  min-width: 39rem;
  width: auto;
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: none;
  opacity: 1;
  position: absolute;
  left: auto;
  visibility: visible;
  overflow: visible;
  padding: 15px 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  font-size: small;
  font-family: monospace;
}
a.user-compte {
  font-size: 14px;
  color: #333;
}

#no-data-to-display {
  font-size: medium !important;
  text-transform: inherit !important;
}
.ui.dropdown .menu > .header {
  font-size: 14px !important;
  text-transform: none !important;
}
div#item-dropdown-bell {
  margin-left: -100px;
}
.ui.top.left.pointing.dropdown > .menu:after {
  left: 8.9em !important;
}
.ui.pointing.dropdown > .menu:after {
  width: 13px !important;
  height: 13px !important;
}
.ui.top.left.pointing.dropdown > .menu:after {
  top: -7px !important;
}
.ui.top.right.pointing.dropdown > .menu:after {
  top: -7px !important;
}

/* search with semantic */
div#search-bakeliste {
  max-width: 200% !important;
  width: 200%;
  margin-left: -100% !important;
}

input#input-search-bakeliste {
  width: inherit;
  border-radius: 20px;
}
a.item-message {
  color: rgb(20, 20, 20);
  text-decoration: none;
  text-transform: capitalize;
  font-family: sans-serif;
}
a.item-historiques {
  color: rgb(20, 20, 20);
  text-decoration: none;
  text-transform: lowercase;
  font-family: sans-serif;
}
a.item-message:hover {
  text-decoration: none;
}
a.item-historiques:hover {
  text-decoration: none;
}
.voir-plus {
  text-align: center;
}
small#time-ago {
  float: right;
  text-transform: lowercase;
}
span#message-envoyer {
  text-transform: lowercase;
}
a.voir-plus.active {
  margin-left: 112px;
}
@media (max-width: 767px) {
  div#search-bakeliste {
    display: none;
  }
  div#item-comment {
    min-width: 35rem;
    margin-left: -167px;
    padding-left: 18px;
  }
  div#item-history {
    margin-left: -25rem;
    min-width: 36rem;
  }

  small#time-ago {
    display: block;
    padding: 9px 6px 6px;
    float: inherit;
  }
  #icon-mobile {
    font-size: 20px;
  }
  img#icon-mobile {
    width: 26px;
  }
  #user-avatar-fullname {
    display: none;
  }

  #item-history:after {
    top: -7px !important;
    left: 72% !important;
  }
}
/* drpdown menu */
.dropdown-container {
  display: none;
  /* background-color: #fff; */
  padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.sidenav li,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #009688;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
