@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/DIN\ Alternate\ Regular.otf');
}

div.bakeliste-chart-container {
    text-align: right;
}
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
#ass{
    background-color:#009688
}

#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #5cb85c;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}

/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */
.add-bakeliste-btn-container {
    text-align: center;
}

h4#myModalLabel {
    font-size: 21px;
}
span.item_label {
    font-size: 15px;
    font-weight: 600;
}
.counting-btn {
    width: 100%;
    margin-bottom: 10px;
    background: transparent;
    color: #000;
    border-color: #009688;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding: 12px;
}
.counting-btn:hover{
    background:transparent;
    border-color: #ff9800;
    color: #009688;
}
.counting-btn span.badge {
    background: #009688;
    color: #fff;
    padding: 8px;
    font-size: 22px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: block;
    position: absolute;
    right: 22px;
    top: 8px;
}
.count-intern-icon-container.dcplb {
    background: transparent;
    color: #009688;
}
span.degree-title.dcplb {
    font-size: 12px !important;
}
.option-item-btn {
    padding: 24px;
    font-size: 17px;
    font-weight: 600;
}
.option-item-container {
    text-align: center;
}
.bakeliste_in_immersion, .bakeliste_in_immersion a {
    font-weight: 800;
    color: #000 !important;
    font-size: 16px;
    
}
.alerty_immersion {
    font-size: 12px;
    color: #ec4134;
    margin-top: 7px;
}

.alerty_immersion i {
    font-size: 15px;
}

.component-ListeBakelistes .react-bs-table.react-bs-table-bordered {
    margin-top: 6px !important;
}
.verty{
    font-weight: 800;
    color: #000 !important;
    font-size: 16px;
}
.is_completed_training{
    background: #F77975 !important;
}
.is_completed_training a{
    color: #fff;
    font-weight: 600;
}
.is_completed_training:hover{
    background: #ff9800 !important;
    color: #fff;
    font-weight: 600;
}
.alerty_news {
    font-size: 17px;
}
.pseudo-comment {
    position: relative;
}
span.comment-counter {
    position: absolute;
    top: -17px;
    font-size: 15px;
    background: red;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* left: 0; */
    right: 0;
    margin: auto;
    font-weight: 600;
    line-height: 30px;
}