.validation-btn-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.validation-btn-container i {
    font-size: 62px;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn {
    width: 90px;
    height: 90px;
    border-radius: 15% 50% 15% 50%;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn:hover {
    background: #009688 !important;
}