.ui.small.image.bp_avatar_img-user {
  width: 73px;
}
small.petit-message {
  font-weight: 100;
}
.ui.items.item-group-message {
  padding-left: 63px;
  padding-right: 63px;
  text-align: justify;
  padding-bottom: 20px !important;
}
.ui.items>.item>.content {
 min-height: auto !important;
}
h5.rounded-circle {
  color: black;
  padding: 11px;
  margin-left: 14px;
}
span.user-messages {
  padding-left: 11px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px){
  .ui.items:not(.unstackable)>.item>.image, .ui.items:not(.unstackable)>.item>.image>img {
    width: 42px !important;
    margin-left: -5px !important;
    top: 63px;
    margin-right: 4px;
    }
    .ui.items:not(.unstackable)>.item>.image+.content {
      display: block;
      padding: 1.5em 0 0;
      margin-bottom: -58px !important;
  }
     
    .ui.items:not(.unstackable)>.item {
      display: block !important;
      width: 133%;
      margin-left: -39px !important;
    }
    p#timeAgo-listemessage {
      padding: 0 37px;
      margin-top: -10px;
  }
  #header-list-message{
    margin-left:39px !important;
  }
  p.email-message {
    padding: 0 37px !important;
}
} 


// new interface
.row.messages {
    margin-top: -20px;
}
.col-sm-12.mssage-nav {
  position: fixed;
  z-index: 100;
}
nav.navbar.navbar-light.bg-light.message-left {
  background-color: #f8f9fa !important;
  padding-left: 15px;
  margin-left: -15px;
  top: -3px;
  width: 101%;
}
  nav.navbar.navbar-light.bg-light.message-right {
  background-color: #f8f9fa!important;
  margin-right: -5%;
  margin-left: -118px;;
  top: -3px;
  border-left: 9px solid #80808069;
  border-radius: 0;
}

.col-sm-4.scrollpy-ms {
  top: 47px;
}

.side {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 29.3%;
  overflow-y: scroll;
}
.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
}

.side-two {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;

}
.mb-3.mx-auto.reseaux {
  margin-left: auto!important;
  background: orange;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 66%;
  position: relative;
  border: 2px solid #fff;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  height: 462px;
}

.sideBar-body {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #f7f7f7;
  height: 72px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-body:hover {
  background-color: #f2f2f2;
}

.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding: 10px !important;
}

.name-meta {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.sideBar-time {
  padding: 10px !important;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, .4);
  vertical-align: baseline;
}
