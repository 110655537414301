.jeema_coder {
  height: 625px;
  background-color: rgb(7, 149, 115);
  /* opacity: 60%; */
}
.conex {
  color: rgb(7, 149, 115);
  font-weight: 700;
}
.class-form {
  border: 1 rgb(7, 149, 115);
}
.btn-connexion {
  color: white;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  background-color: rgb(7, 149, 115);
  border-color: transparent;
  font-weight: 800;
}
.mdpo {
  color: rgb(7, 149, 115);
  font-size: 11px;
  background-color: white;
}
div#forget_container a.active {
  background: transparent;
  color: rgb(7, 149, 115);
  margin: 5px 0 !important;
  display: inline-block;
}
.conex {
  color: rgb(7, 149, 115);
  font-weight: 700;
  font-size: 28px;
}
.login-form-container {
  height: 100vh;
}
.jeema_coder {
  height: 100vh;
}
.loginify {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
