#btnAjouter {
    color: #ffffff;
}
/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */
.conditionnal-input {
    background: #e8e8e8;
    padding: 10px;
    border-radius: 5px;
}