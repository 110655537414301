@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/DIN\ Alternate\ Regular.otf');
}

div.bakeliste-chart-container {
    text-align: right;
}
#ass{
    background-color:#009688
}

#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #5cb85c;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}

/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */
.add-bakeliste-btn-container {
    text-align: center;
}

h4#myModalLabel {
    font-size: 21px;
}
span.item_label {
    font-size: 15px;
    font-weight: 600;
}
.info-box-icon i {
    color: #fff;
}