body {
  background-color: #eee;
  font-family: "Manrope", sans-serif;
}

.news-card {
  border-radius: 8px;
}

.news-feed-image {
  border-radius: 8px;
  width: 100%;
}

.date {
  font-size: 12px;
}

.username {
  color: blue;
}

.share {
  color: blue;
}
.livraison {
  width: 50%;
}

.hearth-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 1px;
  text-align: center;
  align-items: center;
  /* padding: 20px; */
  cursor: pointer;
  border: 0;
  padding: 13px;
  display: flex;
}

.status {
  font-family: "Neucha", sans-serif;
}

#like {
  font-size: var(--norm-size);
  position: relative;
  color: var(--like-color);
}

.chngBlock {
  position: absolute;
  top: 20px;
  left: 20px;
}

.chngBtn {
  background: lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: "Neucha", sans-serif;
  letter-spacing: 0.13em;
  outline: none;
  border: none;
}

.btn-bakeli-green {
  background-color: #009688;
  border: #009688;
}
.btn-bakeli-red {
  background-color: #f34336;
  border: #f34336;
}
.btn-bakeli-orange {
  background-color: #ff9800;
  border: #ff9800;
}
.row.btn-group {
  display: flex;
}

.fa-feedback {
  color: white;
}

@keyframes like {
  15% {
    font-size: 80px;
  }
  40% {
    font-size: 20px;
  }
  70% {
    font-size: 30px;
  }
  80% {
    font-size: 80px;
  }
  to {
    font-size: 60px;
  }
}

@keyframes storm {
  10% {
    margin-left: 30px;
    margin-right: 20px;
  }
  20% {
    margin-right: 30px;
  }
  30% {
    margin-left: 40px;
  }
  40% {
    margin-right: 40px;
  }
  50% {
    margin-left: 50px;
  }
  60% {
    margin-right: 50px;
  }
  70% {
    margin-left: 60px;
  }
  80% {
    margin-right: 60px;
  }
  90% {
    margin-left: 70px;
    margin-right: 70px;
  }
  to {
    margin-right: 0;
    margin-left: 0;
  }
}
