button#btn-signal-probleme {
    margin-top: 27px !important;
    border-radius: 8px !important;
}
textarea#input-message {
  /*  border-color: teal !important; */
    border-radius: 8px !important;
}
input#input-message {
  /*  border-color: teal !important; */
    border-radius: 5px !important;
}
div#segment-form-mss {
    padding: 14px;
    border-radius: 13px;
    /* border-color: orange; */
}
.ui.message.return-to-login-message {
    text-align: center;
    border-color: teal !important;
    border: 1px solid;
    border-radius: 8px;
}
a.return-to-login.active {
    font-size: initial;
    color: teal;
    font-family: monospace;
}
p.mt-5.mb-0.text-gray-400.text-center.bakeli-tech-footer {
    margin-top: 2px !important;
    margin-right: 102px!important;
    font-weight: 800;
}
h1.ui.teal.center.aligned.header.signaler-probleme {
    font-family: -webkit-pictograph;
    padding: 12px;
}