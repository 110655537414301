
#register-form {
       
    margin-left: 3rem !important;
    
}
 #segment-form{
    border-radius: 20px;
    padding-left: 0px;
    padding-bottom: 63px;
 }
 button#btn-register {
    width: 94%;
    top: 18px;
    border-radius: 12px;
}

.ui.teal.message {
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    font-size: small;
    text-align: center;
    font-family: monospace;
    margin-top: 68px;
}
.ui.teal.message .header {
    font-size: initial;
}
.return-login{
    color: teal
}
.register-form-container{
    margin-top: 70px;
}