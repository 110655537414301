.component-entreprises-partenaires section.content {
    background: #fff;
}
section.content-header {
    /* background: #fff;
    border-bottom: 1px solid #ff9800;
    padding: 2px 0 44px 10px;
    margin-top: 6px; */

    background: #fff;
    border-bottom: 1px solid #ff9800;
    padding: 2px 0 44px 10px;
    margin-top: 55px;
}
.no-content-wrapper ol.breadcrumb {
    background: #ececec !important;
}
.companies_container {
    margin-top: 52px;
}
.content-header > h1 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 13px;
}
.add-company-btn-container {
    text-align: center;
}
.add-company-btn-container .button {
    border-radius: 50%;
    background: #009688;
}
h4#showDetailsModalLabel {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}