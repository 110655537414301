.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /*
   * Base structure
   */
  
  /* Move down content because we have a fixed navbar that is 50px tall */
 /*  body {
    padding-top: 50px;
  } */
  
  
  /*
   * Global add-ons
   */
  
  .sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  /*
   * Top navigation
   * Hide default border to remove 1px line.
   */
  .navbar-fixed-top {
    border: 0;
  }
  
  /*
   * Sidebar
   */
  
  /* Hide for mobile, show later */
  /* .sidebar {
    display: none;
  } */
  @media (min-width: 768px) {
    .sidebar {
      position: fixed;
      top: 51px;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: block;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
      background-color: #f5f5f5;
      border-right: 1px solid #eee;
    }
  }
  
  /* Sidebar navigation */
  .nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
  }
  .nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
  }
  
  
  /*
   * Main content
   */
  
  .main {
    padding: 20px;
  }
  @media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .main .page-header {
    margin-top: 0;
  }
  
  
  /*
   * Placeholder dashboard ideas
   */
  
  .placeholders {
    margin-bottom: 30px;
    text-align: center;
  }
  .placeholders h4 {
    margin-bottom: 0;
  }
  .placeholder {
    margin-bottom: 20px;
  }
  .placeholder img {
    display: inline-block;
    border-radius: 50%;
  }
/***********************************************/

/* --------------------------------------------------
:: Dashboard Widget Panels
-------------------------------------------------- */

.panel-widget .title {
    text-transform: uppercase;
}

.panel-widget .text-huge {
    font-size: 36px;
}

.panel-widget .icon {
    position: absolute;
    right: 20px;
}

.panel-green {
    background-color: #1fa67b;
    color: #fff;
}

.panel-purple {
    background-color: #727cb6;
    color: #fff;
}
.panel-volkeno-green{
  background-color: #009688;
  color: #fff;
}
.panel-volkeno-orange{
  background-color: #ff9800;
  color: #fff;
}
.panel-volkeno-red{
  background-color: #F44336;
  color: #fff;
}

.list-group-item img.img-circle {
    height: 30px;
}

a.panel-link:hover {
    text-decoration: none;
}

.dashboard-panel {
    color: #444;
}

.dashboard-panel:hover {
    text-decoration: none;
}

.dashboard-panel .icon {
    text-align: center;
    font-size: 36px;
}

.dashboard-panel .lead {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.dashboard-panel:hover {
    color: #337ab7;
    background-color: #fbfbfb;
}

.list-group-item .list-time {
    position: absolute;
    top: 2px;
    right: 15px;
}

/* .add-coach-btn-container {
    text-align: center;
}
.add_coach_btn, .add_bakeliste_btn, .liste_prospect {
    width: 100px;
    height: 100px;
    display: inline-block;
    position: relative;
    margin: 25px 0;
}
.add_coach_btn i, .add_bakeliste_btn i, .liste_prospect i {
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 75px;  
} */
.add_bakeliste_container a:first-child {
  background: #009688;
  border-radius: 50%;
}
.add_bakeliste_container a:nth-child(2) {
  background: #ff9800;
  border-radius: 50%;
}
.page-prospect-link {
  text-align: right;
}

.table_bakeliste_inscrit .panel-heading {
  background: #009688;
  color: #fff;
  border-color: #009688;
}

.modal-header {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
.table_bakeliste_inscrit .panel {
  border-color: #009688;
}
.coachBakelisteListe-container table tr, .coachBakelisteListe-container table tr td {
  vertical-align: middle;
}
.toggle-btn {
  width: 100%;
}
.absence-btn, .delay-btn {
  padding: 18px;
  width: 110px;
  font-size: 20px;
  font-weight: 600;
}
.release_info_col .box-body {
  font-size: 13px;
}

.release_info_col h3.box-title {
  text-align: center !important;
  font-weight: 600;
}
.dashboard-coach-container span.info-box-text {
  color: #ff9800;
  font-weight: 600;
}

.dashboard-coach-container span.info-box-number {
  color: #009688;
  font-size: 25px;
}
.dashboard-coach-container i.fas.fa-user-graduate {
  color: #fff;
}
.is_completed_training{
  background: #F77975 !important;
  color: #fff;
  font-weight: 600;
}
.is_completed_training a{
  color: #fff;
  font-weight: 600;
}
.is_completed_training:hover{
  background: #009688 !important;
  color: #fff;
  font-weight: 600;
}
.pseudo-comment {
  position: relative;
}
span.comment-counter {
  position: absolute;
  top: -17px;
  font-size: 15px;
  background: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* left: 0; */
  right: 0;
  margin: auto;
  font-weight: 600;
  line-height: 30px;
}
.floate{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:rgb(0,150,136);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-floate{
	margin-top:22px;
}
