.row_espace label {
    margin-left: 15px;
}
.col-md-4.col-md-offset-4.add_container {
    text-align: center;
}
.add_coach_btn.button {
    border-radius: 50%;
}
.date_alerty {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-color: #ff9800 !important;
}
