.delay-count-container span.info-box-text {
    color: #ff9800;
    font-weight: 600;
  }
  
  .delay-count-container span.info-box-number {
    color: #009688;
    font-size: 25px;
  }
  .delay-count-container i.fas.fa-user-graduate {
    color: #fff;
  }
  .delay-table-col .react-bs-table-container {
    margin: 23px;
}

.da-row ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.da-row .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #ff9800 !important;
    border-top: 2px solid #ff9800 !important;
}


h4.delay-count-title {
    margin-left: 15px;
    font-weight: 600;
    font-size: 21px;
    text-transform: uppercase;
}