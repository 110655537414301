@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/DIN\ Alternate\ Regular.otf');
 }
#assignBody{
    font-family: 'Din'

}
#btn_assign{
    background-color: #009688;
    -webkit-text-fill-color: #ffffff;
    border-color: #D1D1D1;
    font-family: 'Din';
    color: #fff;

}
.add-prospect-btn-container a {
  border-radius: 50%;
}
#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }

  /* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */
.add_prospect_container{
  text-align: center;
}
h4#conv_title {
  font-size: 25px;
  font-weight: 900;
}
.submit_and_progress_container button {
  margin-right: 12px;
}
.list_prospect_container .modal-header {
  background: #009688;
  text-align: center;
  color: #fff;
  font-weight: 700 !important;
}
.list_prospect_container button.close span {
  font-size: 32px;
}








.add-reporting-btn-container {
  text-align: center;
}

.search-ii {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.component-Reportings {
  position: relative;
}

.react-bs-table.react-bs-table-bordered {
  margin-top: 40px;
}

.prospect_infos_link{
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}
tr.called-prospect a {
  color: #000;
  font-weight: 600;
}
.called-prospect {
  background: #60F18E !important;
  color: #000;
}

.prospectw-table-col .react-bs-table-container,
.prospectw-table-row  .react-bs-table-container{
  margin: 23px;
}

.prospect-row-row ul li a {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.prospect-row-row .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #ff9800 !important;
  border-top: 2px solid #ff9800 !important;
}
.prospect_item_container {
  font-size: 18px;
}
.comments-prospects-container .comment-item {
  background: transparent;
}
.comments-prospects-container .comment-title {
  font-weight: 600;
}
div#loader2 {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 24px;
  border-top: 16px solid #009688;
  animation: spin 2s linear infinite;
  /* border: 16px solid #f3f3f3; */
  z-index: 1;
  border-radius: 50%;
}

.loader-container {
  padding: 41px;
  position: relative;
}
.loader-container i {
  display: block;
  top: 45px;
  position: relative;
}


