
 
  /* messenger*/
  /*
  .msg-header img{
    max-width: 100%;
    border-radius: 50%;
  }
  .msg-header
  {
    
    width: 100%;
    height: 25%;
    border-bottom: none;
    display: inline-block;
    background-color: rgb(0, 150,136);
  }
  .msg-header-img
  {
    border-radius: 50%;
    width: 40px;
    margin-left: 5%;
    margin-top: 10px;
    float: left;

  }
  .activer
  {
    width: 120px;
    float: left;
    margin-top: 17px;

  }
  .activer h4{
    font-size: 20px;
    margin-left: 10px;
    margin-bottom:4px ;
    color: #fff;
  }

  .chat-page
  {
  background-color:white ;
  border-radius: 30px;
  }
  .msg-inbox
  {
   
    overflow: hidden;
  
  }
  .chats
  {
    padding: 30px 15px 0 25px;
    overflow: auto;
    height: 290px;
    position : relative;
    bottom:0;

  }
  .msg-page
  {
    height: auto;
   
    margin-right:0;
  }
  .received-chats-img
  {
    display: inline-block;
    width: 20px;
    align-items:left;

  }
  .received-msg
  {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
  }
  .received-msg-inbox
  {
    width: 80%;
  }
  .received-msg-inbox p{
    background: #efefef none no-repeat 0 0;
    border-radius: 20px;
    color:black;
    font-size: 14px;
    margin: 0px;
    padding: 5px 15px 5px 20px;
    width: 100%;
  }
  .time
  {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
  .outgoing-chats
  {
    overflow: hidden;
    margin: 26px 20px;
  }
  .outgoing-chats-msg p
  {
background: #efefef  none repeat scroll 0 0;
color: black;
border-radius: 10px;
font-size: 14px;
margin: 0;
padding: 5px 10px 5px 12px;
width: 100px;
  }
 
  .outgoing-chats-msg{
    text-align: right;
    width: 66px;
    margin-left: 82%;
  }
  .outgoing-chats-img
  {
    display: inline-block;
    width: 20px;
    float:right;

  }
  
  .msg-bottom
  {width: 100%;
    height: 25%;
    border-bottom: none;
    display: inline-block;
    background-color: rgb(0, 150,136);
    margin-top:-4px auto ;
  }
  
  .input-group{
    position: relative !important ;
    float: left;
    top: 2px;
    margin-top: 13px;
    margin-left: 20px;
    outline: none !important;
    border-radius: 20px;
    width: 84% !important;
    background-color:white;
  }
  .form-control
  {
    border:none !important;
    border-radius: 20px !important;
   
  }
  
  .input-group-text
  {
    background: transparent !important;
    border: none !important;
   
  }
  
  .input-group .fa{
    position: absolute !important;
    color: rgb(255,184,79);
    float: right;
    top: -12px;
    right: 22px;
font-size: 21px;
width: 50px;
  }
  .bottom-icons
  {
    float: right;
    margin-top: 17px;
    width: 100px !important;
    margin-right: 22px;
  }
  .bottom-icons .fa
  {
    color:#fff;
    padding: 15px;
    right: 55px;
    width: 50px;
    font-size: 21px;
    position: absolute !important;
    float: right;
  }
  .form-control:focus{
    border-color: none !important;
    box-shadow: none !important;
    border-radius: 20px;
  }
  
  .wrappers {
    text-align: center;
    margin-left: 30%;
}
.wrappers1 {
  text-align: center;
  margin-left: 0%;
}

.button {
    position: absolute;
    top: 50%;
}
.pan{
  float: right;
margin-left:118%;
top:-12px;
position: absolute !important;
padding: 15px;
}

/*---------chat window---------------*/

/*
.container{
  max-width:800px;
}
*/
.msg-header img{
  max-width: 100%;
  border-radius: 50%;
}

.msg-header
{
  position:fixed !important;
  top: 7% !important;
  left:219px !important;
  right: 0px;
  width: 100%;
  height: 70px;
 /* border-bottom: none; */
  display: inline-block;
  background-color: rgb(0, 150,136);
  z-index: 100;
}
.msg-header-img
{
  border-radius: 50%;
  width: 40px;
  margin-left: 5%;
  margin-top: 10px;
  float: left;
  

}
.activer
{
  width: 120px;
  float: left;
  margin-top: 17px;

}
.activer h4{
  font-size: 20px;
  margin-left: 10px;
  margin-bottom:4px ;
  color: #fff;
}
.inbox_people {
background: #fff;
float: left;
overflow: hidden;
width: 30%;
border-right: 1px solid #ddd;
}

.inbox_msg {
/*border: 1px solid #ddd;*/
clear: both;
overflow: hidden;
height: 500% !important;
}

.chat_ib h5 span {
font-size: 13px;
float: right;
}

.chat_ib p {
  font-size: 12px;
  color: #989898;
  margin: auto;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat_img {
float: left;
width: 11%;
}

.chat_img img {
width: 100%
}

.chat_ib {
float: left;
padding: 0 0 0 15px;
width: 88%;
}

.chat_people {
overflow: hidden;
clear: both;
}

.chat_list {
border-bottom: 1px solid #ddd;
margin: 0;
padding: 18px 16px 10px;
}

.inbox_chat {
height: 550px;
overflow-y: scroll;
}

.active_chat {
background: #e8f6ff;
}
.incoming_msg{
  margin-top: -30px !important;
  margin-left: 5%;
}

.incoming_msg_img {
display: inline-block;
width: 6%;

}

.incoming_msg_img img {
width: 100%;

}
.outgoing-msg-img img{
  width: 100%;
}
.outgoing-msg-img{
  display: inline-block;
width: 6%;
margin-right:0 ;
float:right;
}
.received_msg {
display: inline-block;
padding: 0 0 0 10px;
vertical-align: top;
width: 92%;
top:-95px;
}

.received_withd_msg p {
background: #ebebeb none repeat scroll 0 0;
border-radius: 0 15px 15px 15px;
color: #080808;
font-size: 14px;
left:5% ;
margin: 0;
padding: 5px 10px 5px 12px;
width: 100%;

}

.time_date {
color: #747474;
display: block;
font-size: 12px;
margin: 8px 0 0;
}

.received_withd_msg {
width: 57%;
}

.mesgs{
float: left;
padding: 30px 15px 0 25px;
width:auto;
margin-right: auto;
}

.msg_send_btn {
	background: rgb(255,184,79) none repeat scroll 0 0;
	border:none;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	height: 33px;
	position: absolute;
	right: 21%;
	top: 15px;
	width: 33px;
}


.outgoing_msg {
overflow: hidden;
margin: 26px 0 26px;
top:-20px;
margin-right: 8%;
}

.sent_msg {
float: right;
right: 8%;
width: auto ;
}
.sent_msg p{
  background: #ebebeb none repeat scroll 0 0;
border-radius: 0 15px 15px 15px;
color: #080808;
font-size: 14px;
right:5% ;
margin: 0;
padding: 5px 10px 5px 12px;
width: auto;
}
.input_msg_write  {
background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
border: medium none;
color: #4c4c4c;
font-size: 15px;
min-height: 48px;
width: 100%;
outline:none;
}

.type_msg {
  
position: relative;
}

 

.messaging {
padding: 0 0 50px 0;
}

.msg_history {
/*
padding: 30px 15px 0 25px;
    overflow:auto ; 
    height: 200px;
    position : relative;
    bottom:0; */
  /*  height: 100%;*/
/*  width: 100%; */
 /* overflow: hidden;*/
 /* padding: 0 auto 0 auto; */
  /*margin: 0; */
  margin: 0;
  padding: 0 0 50px 0;
  margin-top: 60px;
  margin-bottom: 10px;
  margin-top:-10%;
    scroll-behavior: smooth;
}
/*************/
/*
input[type=text] {

     margin:8px 0; 
     outline:none;
      padding:8px;
       box-sizing:border-box;
       position: relative !important ;
       float: left;
       top: 0px;
       margin-top: 13px;
       margin-left: 20px;
       outline: none !important;
       border-radius: 20px;
       width: 84% !important;
       background-color:white;
       }
  input[type=text]{
  height:35px ;
}
 */
  .input-icone  {
  padding-left:35px;
 }
  .input-icone{
  position:relative; 
}
  .input-icone i{
    padding:9px 8px; 
    position: absolute !important;
    color: rgb(0,150,136);
    float: right;
    top: 15px;
    right: 14%;
font-size: 21px;
width: 50px;
   }
  
.float{
  position: absolute;
  top:-3%;
	width:6%;
	height:40%;
	bottom:20%;
	right:6%;
	background-color: rgb(255,184,79);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	
}

.my-float{
	margin-top:-10px;
}
.test{
  display: block;
  border-radius: 50%;
  width: 35%;
  height: 15%;
  right: 8%;
  background-color: rgb(255,184,79);
  margin: 0;
}
.tdr {
  margin-top:-50%;
  margin-right: -30%;
}
li{
  list-style:none;
}
.inputer{
  
  margin:8px 0; 
  outline:none;
   padding:8px;
    box-sizing:border-box;
    position: relative !important ;
    float: left;
    top: 0px;
    margin-top: 13px;
    margin-left: 15px;
    outline: none !important;
    border-radius: 60px;
    width: 75% !important;
    background-color:white;
    height:25px ;
    padding-left:30px;
}
.form-controle:focus{
  border-color: none !important;
  box-shadow: none !important;
  border-radius: 20px;
}
.wrappers {
  text-align: center;
  margin-left: 25%;
  top:-60%;
}
.wrappers1 {
text-align: center;
margin-left: 0%;
margin-top:-100%;
}
.msg-bottom
  {width: 100%;
    height: 60px;
    border-bottom: none;
    display: inline-block;
    background-color: rgb(0, 150,136);
    bottom: 0;
    position:fixed;
    padding-left: 25px;
    
    
  }


 

.action-editer {
  visibility: hidden;
  right:10%;
 width: 65px;
 background: #ebebeb none repeat scroll 0 0;
border-radius: 0 15px 15px 15px;
color: #080808;
top:20px;

}
.sent_msg:hover .action-editer{
  visibility:visible;
}
.header-icons{
  position: fixed !important;
  width: 100px;
  float: left;
  font-size: 14px;
  right:5%;
  margin-top: 10px;
  margin-left:50% ;
}
.header-icons .fas {
size: 18px;
color: rgb(243, 172, 41);
cursor: pointer;
margin:10px;
}
@media (max-width: 767px) {
  
  .msg-header
{
  position:fixed !important;
  top: 20% !important;
  left:2px !important;
 
}
}
@media screen and (max-width: 800px) {
  .msg img {
  width: 300px;
}
}
@media screen and (max-width: 550px) {
  .msg img {
  width: 200px;
}
}
@media (max-width: 767px) {
     .header-icons{
      margin:3px 0; 
      outline:none;
       padding:8px;
       float: left;
       right: 8% !important;
       bottom: 0px;
       margin-top: 13px;
      
     } 
     .header-icons .fas {

      color: rgb(0, 150,136);
      cursor: pointer;
      margin:10px;
      bottom:-5px;
      }
}

@media (max-width: 767px){
  .msg_send_btn {
    
    
    right: 10% !important;
    
  }
}
 
.image-upload > input
{
    display: none;
}

.image-upload img
{
    width: 80px;
    cursor: pointer;
}