/* Vertical nav tabs */
/* .tabs-left {
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.tabs-left > li {
  float: none;
  margin: 0px;
}

.tabs-left > li.active > a,
.tabs-left > li.active > a:hover,
.tabs-left > li.active > a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
  background: #f90;
  border: none;
  border-radius: 0px;
  margin: 0px;
}
.nav-tabs > li > a:hover {
  line-height: 1.42857143;
  border: 1px solid transparent;
}
.tabs-left > li.active > a::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid #f90;
  display: block;
  width: 0;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #f90;
} */

.radiobtn {
  position: relative;
  display: block;
}
.radiobtn label {
  display: block;
  background: #fee8c3;
  color: #444;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #fdd591;
  margin-bottom: 5px;
  cursor: pointer;
}
.radiobtn label:after,
.radiobtn label:before {
  content: "";
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #fdcb77;
}
.radiobtn label:before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}
.radiobtn input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;
}
.radiobtn input[type="radio"]:checked + label {
  background: #fdcb77;
  animation-name: blink;
  animation-duration: 1s;
  border-color: #fcae2c;
}
.radiobtn input[type="radio"]:checked + label:after {
  background: #fcae2c;
}
.radiobtn input[type="radio"]:checked + label:before {
  width: 20px;
  height: 20px;
}
@keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}
