/* .box{
    padding:60px 0px;
} */

.box-part{
    background:rgb(235, 235, 235);
    border-radius:0;
    padding:60px 10px;
    margin:30px 0px;
}
.text{
    margin:20px 0px;
}

/* .fa{
     color:#4183D7;
} */
.module_container img {
    /* max-width: 100%; */
  border-radius: 50%;
  width: 40px;
  margin-left: 5%;
  margin-top: -20px;
  float: left;
}
.activer
{
  width: 150px;
  float: left;
  margin-top: -15%;
  margin-left: 50px;
}
.activer p{
  font-size: 20px;
  margin-left: 70px;
  display: inline-flex;
  /* margin-bottom:4px ; */
  /* color: #fff; */
}