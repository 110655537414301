@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/DIN\ Alternate\ Regular.otf');
}

#assignBody {
    font-family: 'Din'
}

#btn_assign {
    background-color: #F9F9F9;
    -webkit-text-fill-color: #009688;
    border-color: #D1D1D1;
    font-family: 'Din'
}
.add-coach-btn-container a {
    border-radius: 50%;
}

#loader {
    position: absolute;
    left: 40%;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}

.tacheContent_container {
    background: #ececec;
    padding: 7px;
    border-radius: 7px;
}
.tacheState_container span.label_content {
    display: inline-block;
    font-size: 22px;
}
.tacheDuration_container span.label_content {
    font-size: 22px;
    background: #009688;
    padding: 10px;
}
span.alerty {
    font-size: 11px;
    color: #ff0000;
}
.item-label {
    font-weight: 600;
}
.no-saved-col h3.box-title {
    text-align: center !important;
}
.no-saved-col .box-header.with-border {
    text-align: center;
}
.assignation-type-choice {
    background: #ececec;
}