#editor-mobile {
    border-radius: 3px;
}
div#editor-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.text-editor-Mobile {
    margin-top: -180px;
}

.ql-editor.ql-blank {
    height: 296px;
}

button#btn-register {
    width: 100% !important;
    border-radius: 6px !important;
}