@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

.profile-container {
	font-family: Ubuntu;
	margin-top: 60px;
	border:1px solid #d9d9d9;
	height:calc(80vh - 10px);
	box-shadow: 1px 10px 10px rgba(0,0,0,0.2);
}
h1, h2, h3 {
	font-family: Ubuntu;
}

.profile-photo {
	position: relative;
	border:1px solid #d9d9d9;
	width:220px;
	height:220px;
	border-radius: 50%;	
	margin:0 auto;
}

.text-center {
	text-align: center;
}

.profile-name {
	color:#009688;
}

.profile-description {
	width:80%;
	margin:0 auto;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	font-size: 19px;
}

.profile-action {
	text-align: center;
	width:70%;
	margin:0 auto;
}

.profile-action .edit-profile,
.profile-action .edit-password
 {
	display: inline-block;
	color: #009688;
	background-color: lightgrey;
	border: 1px solid #009688;
	background-color: #ffffff;
	padding:5px 10px;
	margin-top: 15px;
	margin-right: auto;
	margin-left: auto;
	color:#009688;

	text-align: center;
	font-size: 1.2em;
	text-transform: uppercase;
	transition: all ease-in-out .3s;
}

.profile-action .edit-profile:hover,
.profile-action .edit-password:hover
 {
	background-color: #009688;
	color:#ffffff;
}

.profile-divider {
	display: block;
	border:1px solid #009688;
	width:10%;
	margin:10px auto;
}

.profile-img {
	width:100%;
	border-radius: 50%;
}

.upload-image-handler {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	font-size: 2.9em;
	width:100%;
	height:100%;
	color: #ffffff;
	text-align: center;
	background-color: rgba(0,0,0,0.6);
	cursor:pointer;
	opacity: 1;
	transition:all ease-in-out .3s;
}

.is-hidden {
	opacity: 0;
}

label[for="image"] {
	width:100%;
	height:50px;
	text-align:center;
	line-height: 50px;
	border:1px solid #009688;
	cursor:pointer;
	transition:all ease-in-out .3s;
}
label[for="image"]:hover {
	background-color: #009688;
	color:#ffffff;
}
label[for="image"] + input {
	display: none;
}