.import-item-panel-container .panel-heading {
    background: #009688;
    text-align: center;
    padding: 20px;
    color: #fff;
}
.import-item-panel-container .panel-body {
    font-size: 16px;
    text-align: center;
}
.import-item-panel-container .panel.panel-default {
    border-color: #009688 !important;
}
.import-item-panel-container i.fas.fa-file-import {
    font-size: 35px;
}
.import-item-panel-container h3.panel-title {
    font-size: 25px;
}
.import-item-panel-container .panel-footer {
    padding: 15px;
    font-size: 31px;
    text-align: center;
    color: #009688;
    font-weight: 600;
    cursor: pointer;
}
.import-input button {
    padding: 15px;
    width: 100%;
    /* position: absolute;
    right: 0;
    left: 0;
    margin: auto; */
    border-radius: 10px;
    border-color: transparent;
    font-size: 25px;
    background: #009688;
    opacity: .7;
    color: #fff;
}
/* #importCSVModal .modal-body {
    height: 110px;
} */
.import-input button:hover {
    background: transparent;
    border: 1px solid #009688;
    color: #009688;
    opacity: 1;
}