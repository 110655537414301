#frame {
    width: 104%;
    /* min-width: 360px; */
    /* max-width: 1000px; */
    height: 92vh;
    min-height: 300px;
    max-height: 720px;
    background: #E6EAEA;
    margin-top:-3%;
    margin-left: -2%;
  }
  
  #frame #sidepanel {
    
    float: left;
   min-width: 280px; 
    max-width: 340px;
    width: 40%;
    height: 100%;
    background: rgb(230, 230, 230);
    color:black;
    overflow: hidden;
    position: relative;
  }
  
  #frame #sidepanel #profile {
    width: 80%;
    margin: 25px auto;
  }
 
  #frame #sidepanel #profile.expanded .wrap {
    height: 210px;
    line-height: initial;
  }
  #frame #sidepanel #profile.expanded .wrap p {
    margin-top: 20px;
  }
  #frame #sidepanel #profile.expanded .wrap i.expand-button {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  #frame #sidepanel #profile .wrap {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    -moz-transition: 0.3s height ease;
    -o-transition: 0.3s height ease;
    -webkit-transition: 0.3s height ease;
    transition: 0.3s height ease;
  }
 
  #frame #sidepanel #profile .wrap img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #e74c3c;
    height: auto;
    float: left;
    cursor: pointer;
    -moz-transition: 0.3s border ease;
    -o-transition: 0.3s border ease;
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease;
  }
  
  #frame #sidepanel #profile .wrap img.online {
    border: 2px solid #2ecc71;
  }
  #frame #sidepanel #profile .wrap img.away {
    border: 2px solid #f1c40f;
  }
  #frame #sidepanel #profile .wrap img.busy {
    border: 2px solid #e74c3c;
  }
  #frame #sidepanel #profile .wrap img.offline {
    border: 2px solid #95a5a6;
  }
  #frame #sidepanel #profile .wrap p {
    float: left;
    margin-left: 15px;
  }
  
  #frame #sidepanel #profile .wrap i.expand-button {
    float: right;
    margin-top: 23px;
    font-size: 0.8em;
    cursor: pointer;
    color: #435f7a;
  }
  
  #frame #sidepanel #profile .wrap #status-options {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    margin: 70px 0 0 0;
    border-radius: 6px;
    z-index: 99;
    line-height: initial;
    background: #435f7a;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  
  #frame #sidepanel #profile .wrap #status-options.active {
    opacity: 1;
    visibility: visible;
    margin: 75px 0 0 0;
  }
 
  #frame #sidepanel #profile .wrap #status-options:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #435f7a;
    margin: -8px 0 0 24px;
  }
  
  #frame #sidepanel #profile .wrap #status-options ul {
    overflow: hidden;
    border-radius: 6px;
  }
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 30px 18px;
    display: block;
    cursor: pointer;
  }
  
  #frame #sidepanel #profile .wrap #status-options ul li:hover {
    background: #496886;
  }
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0 0 0;
  }
 
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px; 
    background: transparent;
    border-radius: 50%;
    z-index: 0;
  }
  
  #frame #sidepanel #profile .wrap #status-options ul li p {
    padding-left: 12px;
  }
  
  #frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
    background: #2ecc71;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
    border: 1px solid #2ecc71;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
    background: #f1c40f;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
    border: 1px solid #f1c40f;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
    background: #e74c3c;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
    border: 1px solid #e74c3c;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
    background: #95a5a6;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
    border: 1px solid #95a5a6;
  }
  #frame #sidepanel #profile .wrap #expanded {
    padding: 100px 0 0 0;
    display: block;
    line-height: initial !important;
  }
  #frame #sidepanel #profile .wrap #expanded label {
    float: left;
    clear: both;
    margin: 0 8px 5px 0;
    padding: 5px 0;
  }
   #frame #sidepanel #profile .wrap #expanded input {
    border: none;
    margin-bottom: 6px;
    background: with#32465a;
    border-radius: 3px;
    color: #f5f5f5;
    padding: 7px;
    width: calc(100% - 43px);
  } 
   #frame #sidepanel #profile .wrap #expanded input:focus {
    outline: none;
    background: #435f7a;
  }
  #frame #sidepanel #search {
    border-top: 1px solid rgb(230, 230, 230);;
    border-bottom: 1px solid rgb(230, 230, 230);;
    font-weight: 300;
  }
  
  #frame #sidepanel #search label {
    position: absolute;
    margin: 10px 0 0 20px;
  }
  #frame #sidepanel #search input {
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
    padding: 10px 0 10px 46px;
    width: calc(100% - 25px);
    border: none;
    background: rgb(0, 150,136);
    color: #f5f5f5;
  }
  #frame #sidepanel #search input:focus {
    outline: none;
    background: rgb(255,184,79);
  }
  #frame #sidepanel #search input::-webkit-input-placeholder {
    color: #f5f5f5;
  }
  #frame #sidepanel #search input::-moz-placeholder {
    color: #f5f5f5;
  }
  #frame #sidepanel #search input:-ms-input-placeholder {
    color: #f5f5f5;
  }
  #frame #sidepanel #search input:-moz-placeholder {
    color: #f5f5f5;
  } */
  #frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
 
    #frame #sidepanel #contacts::-webkit-scrollbar {
      display: none;
    }
  
  #frame #sidepanel #contacts.expanded {
    height: calc(100% - 334px);
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    width: 8px;
    background: #2c3e50;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    background-color: #243140;
  }
  #frame #sidepanel #contacts ul li.contact {
    position: relative;
    padding: 10px 0 15px 0;
    font-size: 0.9em;
    cursor: pointer;
    margin-left: 0;
  }
  
  #frame #sidepanel #contacts ul li.contact:hover {
    background: rgb(255,184,79);
  }
  #frame #sidepanel #contacts ul li.contact.active {
    background: #32465a;
    border-right: 5px solid #435f7a;
  }
  #frame #sidepanel #contacts ul li.contact.active span.contact-status {
    border: 2px solid #32465a !important;
  }
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 88%;
    margin-left: -7% ;
    position: relative;
    
  }
 
  #frame #sidepanel #contacts ul li.contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #95a5a6;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.online {
    background: #2ecc71;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.away {
    background: #f1c40f;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.busy {
    background: #e74c3c;
  }
  #frame #sidepanel #contacts ul li.contact .wrap img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    padding: 5px 0 0 0;
  }
  
  #frame #sidepanel #contacts ul li.contact .wrap .meta .name {
    font-weight: 700;
    
  }
  #frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
    margin: 5px 0 0 0;
    padding: 0 0 1px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
  }
  #frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
  }
  #frame #sidepanel #bottom-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  #frame #sidepanel #bottom-bar button {
    float: left;
    border: none;
    width: 50%;
    padding: 10px 0;
    background: #32465a;
    color: #f5f5f5;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  }
 
  #frame #sidepanel #bottom-bar button:focus {
    outline: none;
  }
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: 1px solid #2c3e50;
  }
  
  #frame #sidepanel #bottom-bar button:hover {
    background: #435f7a;
  }
  #frame #sidepanel #bottom-bar button i {
    margin-right: 3px;
    font-size: 1em;
  }
 
  #frame .content {
    float: right;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  #frame .content .contact-profile {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: rgb(0, 150,136);
  }
  #frame .content .contact-profile img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin: 9px 12px 0 9px;
  }
  #frame .content .contact-profile p {
    float: left;
    padding-top: 3%;
  }
  #frame .content .contact-profile .social-media {
    float: right;
  }
  #frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
  }
  #frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
  }
  #frame .content .contact-profile .social-media i:hover {
    color: #435f7a;
  }
  #frame .content .messages {
    height: auto;
    min-height: calc(100% - 93px);
    max-height: calc(100% - 93px);
    overflow-y: scroll;
     overflow-x: hidden;  
    scroll-behavior: smooth;

  }
 
  #frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  #frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
  #frame .content .messages ul li {
    display: inline-block;
    clear: both;
    scroll-behavior: auto;
    /* float: left; */
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
  }
  #frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
  }
  #frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
  }
  #frame .content .messages ul li.sent p {
    background: #f5f5f5;;
    color: #252323;
  }
  #frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
  }
  #frame .content .messages ul li.replies p {
    background: #f5f5f5;
    float: right;
  }
  #frame .content .messages ul li img {
    width: 22px;
    border-radius: 50%;
    float: left;
  }
  #frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
  }
  
   #frame .content .message-input {
    position: absolute;
    bottom: -5%;
    width: 90%;
    height: 15%;
    z-index: 99;
    margin-left: 3%;
    
  } 
  .chat{
 background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
border: medium rgb(8, 223, 8);
color: #4c4c4c;
font-size: 15px;
min-height: 48px;
width: 100%;
outline:none;
  }  
  
  .quill {
    margin-top: -10% !important;
}

  .ql-editor.ql-blank {
    height: 45px !important;
}

.ql-toolbar.ql-snow {
  top: 89% !important;
  position: absolute !important;
  width: 93%;
}
   #frame .content .message-input .wrap {
    position: relative;
  } 
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #bfbebe !important;
    width: 97.5%;
}

 #frame .content .message-input .wrap input {
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
    float: left;
  /*  border-radius:50% black; */
     width: calc(110% - 90px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: rgb(8, 8, 8);
    background-color: white;
  }  

  
   #frame .content .message-input .wrap input:focus {
   outline: none; 
  }
  #frame .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #ffffff;
    opacity: .5;
    cursor: pointer;
    
  }
 
  #frame .content .message-input .wrap .attachment:hover {
    opacity: 1;
  }
  #frame .content .message-input .wrap button {
   float:right;
    border: none;
    width: 90px;
    padding: 10px 0;
    cursor: pointer;
    background: white; 
    color: rgb(0, 150,136);
    bottom:5%;
  } 
  .rounded-circle{
    visibility: hidden;
  }
  .submiter {
	/*background: rgb(255,184,79) none repeat scroll 0 0;*/
	border:none;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	height: 33px;
	position: absolute;
	right: 7% !important;
  top: 75% !important;
  width: 30px !important; 
}
  
  #frame .content .message-input .wrap button:hover {
    background: #435f7a;
  }
  
  #frame .content .message-input .wrap button:focus {
    outline: none;
  }
  @media screen and (max-width: 767px){
    #frame{
      width: 317px !important;
      margin-top: -25% !important;
       margin-left: -5% !important; 
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel {
      width: 85px !important;
      min-width: 50px;
     position: relative;
    }
    #frame #sidepanel #profile{
    visibility: hidden;
    }
    #frame #sidepanel #search{
      visibility: hidden;
    }
    #frame #sidepanel #contacts ul li.contact .wrap span.online {
      background: #ffffff;
  }
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  
}
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul{
      margin-left: -40%;
      /* margin-top: 25%; */
    }
    #frame #sidepanel #contacts ul li.contact .wrap .meta .name {
      font-weight: 100;
  }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul li.contact {
      padding: 6px 0 5px 8px;
      margin-left:0;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts {
     /* height: calc(100% - 149px);*/
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: -17%;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul li.contact .wrap img {
      margin-right: 0px;
      visibility: hidden;
    }
    .rounded-circle{
      visibility: visible;
    }
    #frame #sidepanel #contacts ul li.contact .wrap span.online {
      visibility: hidden;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul li.contact .wrap {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul li.contact .wrap .meta {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    #frame .content {
     /* width: calc(100% - 58px);*/
     /* min-width: 300px !important; */
     width: 225px !important;
    }
    #frame .content .contact-profile {
      width: 94%;
    height: 60px;
    line-height: 60px;
    margin-left: 0;
    }
    
  }

  @media screen and (max-width: 767px) {
    #frame .content .messages ul li p {
      max-width: 350px;
    }
    #frame .content .messages ul li{
      margin: 15px 15px 5px 15px;
      /* width: calc(100% - 25px);  */
    }
  }


  /*
  
  
 
  @media screen and (max-width: 767px) {
    #frame .content .messages {
      max-height: calc(100% - 105px);
    }
  }
  @media screen and (max-width: 767px) {
    #frame {
      width: 100%;
      height: 100vh;
    }
  }
 
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile {
      width: 100%;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background: #32465a;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap {
      height: 55px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap img {
      width: 40px;
      margin-left: 4px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap p {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap i.expand-button {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options {
      width: 58px;
      margin-top: 57px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options.active {
      margin-top: 62px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame .content .message-input .wrap input {
      padding: 15px 32px 16px 8px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options:before {
      margin-left: 23px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options ul li {
      padding: 15px 0 35px 22px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
      width: 14px;
      height: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
      height: 18px;
      width: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options ul li p {
      display: none;
    }
  }
  @media screen and (min-width: 767px) {
    #frame .content .messages ul li p {
      max-width: 300px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
      height: 18px;
      width: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    #frame .content .message-input .wrap .attachment {
      margin-top: 17px;
      right: 65px;
    }
  }
  @media screen and (min-width: 767px) {
    #frame .content .messages ul li p {
      max-width: 300px;
    }
  }
 
  @media screen and (max-width: 767px) {
    #frame #sidepanel #bottom-bar button:nth-child(1) {
      border-right: none;
      border-bottom: 1px solid #2c3e50;
    }
  }
  @media screen and (max-width: 767px) {
    #frame .content .message-input .wrap button {
      padding: 16px 0;
    }
  }
  @media screen and (max-width: 767px) {
    #frame .content {
      width: calc(100% - 58px);
      min-width: 300px !important;
    }
  }
  @media screen and (min-width: 900px) {
    #frame .content {
      width: calc(100% - 340px);
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #search {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #contacts ul li.contact .wrap {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #bottom-bar button {
      float: none;
      width: 100%;
      padding: 15px 0;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #bottom-bar button i {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: 767px) {
    #frame #sidepanel #bottom-bar button span {
      display: none;
    }
  }
  */
/* chrono */
/* .Chrono {
  margin-top: 75px !important;
  display: inline-block;
  padding: 20px;
  background-color: rgb(0, 150,136);
  border-radius: 3px;
  box-shadow: 0px 0px 1px 0px #656565;
}
.Chrono h1 {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 10px 40px;
  background-color: rgba(51, 178, 202, .3);
  font-size: 40px;
  border: 1px solid rgba(0, 0, 0, .2);
}
.Chrono h1:before {
  content: '';
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 33px;
  background: rgba(255, 255, 255, .2);
} */
/* 
.Chrono .buttons {
  margin-top: 20px;
}
.Chrono button {
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, .2);
}
.Chrono button + button {
  margin-left: 10px;
}
.Chrono button:hover {
  border-bottom-color: #000;
} */
